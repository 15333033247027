<template>
  <div class="profile-modal" ref="profile_modal">
    <div class="profile-change" v-if="changeProfile">
      <div class="profile_change-inner" :class="changeProfile ? 'active' : ''">
        <v-btn class="back-btn" @click="changeProfile = false"></v-btn>
        <ul class="change-lists">
          <li
            v-for="(item, index) in profileImgs"
            :key="index"
            class="change-list"
          >
            <label>
              <input type="radio" name="profile" value="item.value" checked />
              <div class="change_img-box">
                <img :src="require(`@assets/img/login/${item.img}`)" alt="" />
              </div>
            </label>
          </li>
        </ul>
        <div class="profile-save">
          <v-btn class="save-btn">{{ $t("button.save") }}</v-btn>
        </div>
      </div>
    </div>
    <div class="profile-inner" v-else>
      <section class="profile top">
        <div class="top-inner">
          <div class="profile-img">
            <div class="img-area">
              <img src="@assets/img/login/profile_icon01.png" alt="" />
              <button
                type="button"
                class="change-btn"
                @click="changeProfile = true"
              ></button>
            </div>
            <div class="profile-info">
              <div class="profile-name">
                <p class="name">{{ this.getPlayerId }}</p>
                <!-- <v-btn class="paste-name"></v-btn> -->
              </div>
              <!-- BMM 인증을 위한 등급 숨김 처리 -->
              <!-- <div class="profile-grade">
                    <div class="grade-level">
                      <div class="level-inner">
                        <div class="grade01">
                          <img src="@assets/img/login/vipclass_0.png" alt="" />
                        </div>
                        <div class="grade-arrow"></div>
                        <div class="grade02">
                          <img src="@assets/img/login/vipclass_1.png" alt="" />
                        </div>
                        <div class="bet">
                          <p>Bet <span>4967.24</span> more</p>
                        </div>
                      </div>
                    </div>
                    <div class="grade-gauge">
                      <ul class="gauge-lists">
                        <li class="gauge-list active"></li>
                        <li class="gauge-list active"></li>
                        <li class="gauge-list"></li>
                        <li class="gauge-list"></li>
                        <li class="gauge-list"></li>
                      </ul>
                    </div>
                  </div> -->
            </div>
          </div>
          <div class="profile-balance">
            <h3>Balance</h3>
            <div class="balance-amount">
              <p class="amount">
                = {{ settingUserCurrency.Exchange }}
                {{ getUserCurrency }}
              </p>
              <p class="result-amount">
                {{ settingUserCurrency.Symbol }}
                {{ settingCurrency }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="profile bottom">
        <ul class="bottom-lists">
          <li
            class="bottom-list"
            v-for="(item, index) in profileMenuList"
            :key="index"
          >
            <div class="list-inner">
              <v-btn @click="profileMenuClick(item.path)">
                <span class="list-title"
                  ><img
                    :src="require(`@assets/img/login/${item.srcImg}`)"
                    alt=""
                  />{{ item.menuTitle }}</span
                >
                <span class="list-arrow"></span>
              </v-btn>
            </div>
          </li>
        </ul>
        <div class="logout">
          <div class="logout-inner">
            <a @click="logoutButtonClick">
              <span class="logout-title"
                ><img src="@assets/img/login/logout_icon.png" alt="" />{{
                  $t("profileDialog.logout.logoutTitle")
                }}</span
              >
              <span class="logout-arrow"></span>
              <v-dialog
                v-model="alertDialog"
                content-class="alert"
                activator="parent"
                width="auto"
              >
                <AlertDialog
                  @closeDialog="closeAlertDialog"
                  @returnResult="confirmLogout"
                  :message="alertMessage"
                  :is-confirm="true"
                />
              </v-dialog>
            </a>
          </div>
        </div>
      </section>
    </div>
    <v-dialog
      content-class="vertical"
      v-if="walletDialog"
      v-model="walletDialog"
      activator="parent"
      width="auto"
    >
      <WalletDialog @closeDialog="closeWalletDialog" />
    </v-dialog>
    <v-dialog
      content-class="vertical"
      v-if="transectionDialog"
      v-model="transectionDialog"
      activator="parent"
      width="auto"
    >
      <TransectionDialog @closeDialog="closeTransectionDialog" />
    </v-dialog>
    <v-dialog
      content-class="vertical"
      v-if="gameHistoryDialog"
      v-model="gameHistoryDialog"
      activator="parent"
      width="auto"
    >
      <GameHistoryDialog @closeDialog="closeGameHistoryDialog" />
    </v-dialog>
    <v-dialog
      content-class="currency"
      v-if="currencyDialog"
      v-model="currencyDialog"
      activator="parent"
      width="auto"
    >
      <CurrencyDialog @closeDialog="closeCurrencyDialog" />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AuthNamespace, AuthActions } from "@/store/auth";
import {
  CurrencyNameSpace,
  CurrencyActions,
  CurrencyMutaions
} from "@/store/currency";
import EventBus from "@/utils/EventBus";
import AlertDialog from "./AlertDialog.vue";
import TransectionDialog from "./TransectionDialog.vue";
import GameHistoryDialog from "./GameHistoryDialog.vue";
import WalletDialog from "./WalletDialog.vue";
import CurrencyDialog from "./CurrencyDialog.vue";

export default {
  components: {
    AlertDialog,
    TransectionDialog,
    GameHistoryDialog,
    WalletDialog,
    CurrencyDialog
  },
  data() {
    return {
      // alert dialog
      alertDialog: false,
      changeProfile: false,
      transectionDialog: false,
      gameHistoryDialog: false,
      walletDialog: false,
      currencyDialog: false,
      profileImgs: [
        {
          value: "profile00",
          img: "profile_icon00.png"
        },
        {
          value: "profile01",
          img: "profile_icon01.png"
        },
        {
          value: "profile02",
          img: "profile_icon02.png"
        },
        {
          value: "profile03",
          img: "profile_icon03.png"
        },
        {
          value: "profile04",
          img: "profile_icon04.png"
        },
        {
          value: "profile05",
          img: "profile_icon05.png"
        },
        {
          value: "profile06",
          img: "profile_icon06.png"
        },
        {
          value: "profile07",
          img: "profile_icon07.png"
        },
        {
          value: "profile08",
          img: "profile_icon08.png"
        }
      ],
      profileMenuList: [
        // BMM 인증을 위한 임시 주석 처리
        /*{
          srcImg: "transactions_icon.png",
          menuTitle: "Transections"
        },
        {
          srcImg: "gamehistory_icon.png",
          menuTitle: "Game History"
        },
        {
          srcImg: "referral_icon.png",
          menuTitle: "Referral"
        },*/
        {
          srcImg: "security_icon.png",
          path: "Security",
          menuTitle: this.$t("profileDialog.security")
        },
        /*{
          srcImg: "vipclub_icon.png",
          menuTitle: "VIP Club"
        },
        */
        {
          srcImg: "currency_icon.png",
          path: "Currency",
          menuTitle: this.$t("profileDialog.currencyDialog.currencyTitle")
        }
      ],
      alertMessage: {
        title: this.$t("profileDialog.logout.logoutNotice"),
        text: this.$t("profileDialog.logout.logoutDesc"),
        btnText: this.$t("button.confirm")
      },
      userCurrencyDetail: null
    };
  },
  computed: {
    ...mapGetters(AuthNamespace, [
      "getPlayerId",
      "getUserCredit",
      "getUserCurrency"
    ]),
    ...mapGetters(CurrencyNameSpace, ["getCurrencyList", "getCurrencyDetail"]),

    // user가 설정한 currency와 일치하는 currency 정보
    settingUserCurrency() {
      return this.getCurrencyDetail != null ? this.getCurrencyDetail : "";
    },

    //설정한 Currency로 환산 된 Balance = 상단에 표기되는 페소 Balance 값 x Exchange 값(내가 설정한 Currency에 해당)
    settingCurrency() {
      return this.getCurrencyDetail != null
        ? Number(
            this.getCurrencyDetail.Exchange * this.getUserCredit
          ).toLocaleString()
        : "";
    }

    /* CurrencyNumberFormat() {
      let currencyKey = "";
      // 선택된 통화의 locale 업데이트를 위한 i18n numberformat의 key값 찾기
      for (const [key, value] of Object.entries(this.$i18n.numberFormats)) {
        if (value.currency.currency == this.settingUserCurrency.Code) {
          currencyKey = key;
        }
      }
      return currencyKey;
    } */
  },
  mounted() {
    this.ACTION_READ_CURRENCY_LIST().then(res => {
      if (res.status == 200) {
        const currency_list = res.data.list;
        const findIndex = currency_list.findIndex(
          cl => cl.Code === this.getUserCurrency
        );

        this.userCurrencyDetail =
          findIndex != -1 ? currency_list[findIndex] : null;

        this.MUTATION_UPDATE_CURRENCY_DETAIL(this.userCurrencyDetail);
      }
    });
  },
  methods: {
    ...mapActions(AuthNamespace, {
      ACTION_AUTH_LOGOUT: AuthActions.ACTION_AUTH_LOGOUT
    }),
    ...mapActions(CurrencyNameSpace, {
      ACTION_READ_CURRENCY_LIST: CurrencyActions.ACTION_READ_CURRENCY_LIST
    }),
    ...mapMutations(CurrencyNameSpace, {
      MUTATION_UPDATE_CURRENCY_DETAIL:
        CurrencyMutaions.MUTATION_UPDATE_CURRENCY_DETAIL
    }),
    // 모달 닫기 이벤트
    closeDialog() {
      this.$emit("closeDialog");
    },
    // 로그아웃 버튼 클릭 이벤트
    logoutButtonClick() {
      // 로그아웃) 게임페이지일 경우와 아닐 경우 구분
      // 게임 페이지일 경우 closegame event 호출 호출시 profile dialog close
      if (window.location.pathname == "/game") {
        EventBus.$emit("movePageEvent", "logout");
        this.closeDialog(false);
      } else {
        // 게임 페이지 이외의 페이지일 경우 alert modal 호출
        this.alertDialog = true;
      }
    },
    // 프로필 메뉴 클릭 이벤트
    profileMenuClick(menu) {
      switch (menu) {
        case "Transections":
          this.transectionDialog = true;
          break;
        case "Game History":
          this.gameHistoryDialog = true;
          break;
        case "Currency":
          this.currencyDialog = true;
          break;
        case "Security":
          this.$router.push("/security");
          this.closeDialog();
          break;
        default:
          this.$router.push("");
      }
    },
    //alert confirm 클릭 시 발생하는 이벤트
    confirmLogout() {
      //로그아웃 후
      this.ACTION_AUTH_LOGOUT();
      // main page일 경우 main으로 재설정
      if (window.location.pathname == "/") {
        window.location.replace("/");
      } else {
        // mainpage, gamePage가 아닐 경우 reload
        window.location.reload();
      }
    },
    openAlertDialog() {
      this.alertDialog = true;
    },
    closeAlertDialog() {
      this.alertDialog = false;
    },
    closeTransectionDialog() {
      this.transectionDialog = false;
    },
    closeGameHistoryDialog() {
      this.gameHistoryDialog = false;
    },
    closeWalletDialog() {
      this.walletDialog = false;
    },
    closeCurrencyDialog() {
      this.currencyDialog = false;
    }
  }
};
</script>
