<template>
  <div id="xs_navi" class="side-menu" :class="isOpen ? 'active' : ''">
    <div class="menu-inner">
      <!-- BMM 인증을 위해 임시 숨김 영역 처리 -->
      <!-- <ul class="menu-lists">
        <li class="menu-list">
          <div class="menu-box">
            <button></button>
          </div>
        </li>
        <li class="menu-list">
          <div class="menu-box">
            <button></button>
          </div>
        </li>
        <li class="menu-list">
          <div class="menu-box">
            <button></button>
          </div>
        </li>
      </ul> -->
      <div class="flip-menu">
        <div class="allgame-small" @click="$router.push('./all-games')">
          <div class="balloon">
            <p class="balloon-text">All Games</p>
          </div>
        </div>
      </div>

      <div class="game-area">
        <div class="all-games">
          <button class="allgame_btn" @click="clickAllGame">
            <!-- TODO: 심사 이후 숫자 표기 시 url에서 _num 삭제 "allgametap_btn_num -> allgametap_btn" -->
            <!-- <span>13000</span> -->
            <span class="allgame-title">All Games</span>
            <span class="allgame-arrow"></span>
          </button>
        </div>
        <ul class="game-lists">
          <li
            class="game-list"
            v-for="(item, index) in allGameList"
            :key="index"
          >
            <div class="game-box">
              <div class="box_inner">
                <div class="inner_num-box">
                  <div class="game_num-box">
                    <!--  TODO: 심사 이후 숫자 표기 시 주석 해제 -->
                    <!-- <div class="amount">
                      <p class="dollar">$</p>
                      <div class="scroll-numbers">
                        <div
                          class="number"
                          :class="isScroll ? 'scrolling' : ''"
                          v-for="(list, index) in 10"
                          :key="index"
                        >
                          <ul class="scroll-container">
                            <li
                              class="scrolling-number"
                              v-for="(list, index) in countNum"
                              :key="index"
                            >
                              {{ list }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> -->
                    <!-- <span class="gradient-num">${{ item.gradientNum }}</span> -->
                  </div>
                  <!-- <div class="game_num-box sm">
                    <div class="icon-box">
                      <i class="slot-icon"></i>
                    </div>
                    <div class="num-box">
                      <span class="white-num">{{ item.whiteNum }}</span>
                    </div>
                  </div> -->
                </div>
                <div class="title-box">
                  <p
                    class="game-title"
                    v-text="item.title"
                    style="white-space: pre-line"
                  ></p>
                </div>
                <img
                  :src="`${require('@/assets/img/side-menu/' + item.url)}`"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- BMM 인증을 위해 임시 숨김 영역 처리 -->
      <!-- <div class="link-area">
        <ul class="link-lists">
          <li class="link-list">
            <a href="#">About Us</a>
          </li>
          <li class="link-list">
            <a href="#">Terms & Service</a>
          </li>
          <li class="link-list">
            <a href="#">Customer Service</a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>
<script>
import EventBus from "@/utils/EventBus";
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countNum: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ",", "."],
      isScroll: false,
      // TODO: 심사 이후 숫자 표기 시 url에서 _num 삭제 "allgamelist00_num -> allgamelist00"
      allGameList: [
        {
          title: "5 DRAGONS \n ULTRA",
          url: "game01.png"
        },
        {
          title: "COIN \n COMBO",
          url: "game02.png"
        },
        {
          title: "DRAGONS \n RICHES",
          url: "game03.png"
        }
      ]
    };
  },
  mounted() {
    this.isScroll = !this.isScroll;
    setInterval(this.scrollNum, 5000);
  },
  methods: {
    scrollNum() {
      this.isScroll = !this.isScroll;
    },
    clickAllGame() {
      if (window.location.pathname == "/game") {
        EventBus.$emit("movePageEvent", "/all-games");
      } else {
        if (this.$router.currentRoute.path !== "/all-games") {
          this.$router.push("all-games");
        } else {
          return;
        }
      }
    }
  }
};
</script>
