<template>
  <div
    v-if="getMachineId == undefined"
    class="quick-start"
    id="quick-start"
    @mousedown="mousedown"
    @mouseup="mouseup"
  >
    <div class="quick-button" id="quick-button"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { AuthNamespace, AuthMutations } from "@/store/auth";
import { MachineNamespace } from "@/store/machine";

import EventBus from "@/utils/EventBus";

export default {
  props: {},
  data() {
    return {
      isDrag: false,
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      element: null
    };
  },

  computed: {
    ...mapGetters(MachineNamespace, ["getMachineId"]),
    ...mapGetters(AuthNamespace, ["getQuickButtonPoint"])
  },

  mounted() {
    if (this.getMachineId == undefined) {
      this.element = document.getElementById("quick-start");
      if (
        this.getQuickButtonPoint.x !== 0 ||
        this.getQuickButtonPoint.y !== 0
      ) {
        this.element.style.top = this.getQuickButtonPoint.y;
        this.element.style.left = this.getQuickButtonPoint.x;
      }
    }
  },

  methods: {
    ...mapMutations(AuthNamespace, {
      MUTATION_UPDATE_QUICK_BUTTON_POINT:
        AuthMutations.MUTATION_UPDATE_QUICK_BUTTON_POINT
    }),

    // 마우스 클릭
    mousedown(e) {
      this.isDrag = false;

      this.pos3 = e.clientX;
      this.pos4 = e.clientY;

      document.onmousemove = this.mousemove;
    },

    // 마우스 이동
    mousemove(e) {
      e.preventDefault();

      this.isDrag = true;

      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;

      let bPositionOver = false;
      if (this.pos3 < 0 || this.pos3 > window.innerWidth) {
        bPositionOver = true;
      } else if (this.pos4 < 0 || this.pos4 > window.innerHeight) {
        bPositionOver = true;
      }

      if (bPositionOver) {
        document.onmousemove = null;
      } else {
        this.saveQuickButtonPosition();

        this.element.style.top = this.element.offsetTop - this.pos2 + "px";
        this.element.style.left = this.element.offsetLeft - this.pos1 + "px";
      }
    },

    // 마우스 focus out
    mouseup() {
      document.onmousemove = null;

      // 드래가 아닌 클릭한경우
      if (!this.isDrag) {
        EventBus.$emit("quickJoinGame");
      } else {
        this.saveQuickButtonPosition();
      }
    },

    // 버튼 위치 업데이트
    saveQuickButtonPosition() {
      this.MUTATION_UPDATE_QUICK_BUTTON_POINT({
        x: this.element.style.left,
        y: this.element.style.top
      });
    }
  }
};
</script>
