<template>
  <div id="main_page" class="xs-container">
    <!-- BMM 인증으로 인한 배너 숨김 처리 -->
    <!-- banner slide -->
    <!-- <section class="slide-slot xs-section">
      <swiper class="swiper slot-swiper" :options="swiperOption">
        <swiper-slide
          class="slot-slide swiper-slide"
          v-for="(list, index) in 2"
          :key="index"
        >
          <a class="slide-link" href="#">
            <span class="banner-title">live slot games</span>
            <span class="banner-caption">enjoy the most</span>
            <span class="banner-caption2">unique experience</span>
            <img
              class="banner-logo"
              src="@assets/img/xgamesmini_logo.png"
              alt=""
            />
            <video autoplay loop muted>
              <source
                src="@assets/img/banner/banner00.spine.mp4"
                type="video/mp4"
              />
            </video>
          </a>
        </swiper-slide>

        <template v-slot:pagination>
          <div class="swiper-pagination"></div>
        </template>
      </swiper>
    </section> -->
    <!-- category section -->
    <!-- <section class="slot-category xs-section">
      <div class="category-inner">
        <ul class="category-lists">
          <li class="category-list">
            <div class="category-box">
              <p class="category-title">TABLE GAMES</p>
              <div class="animation-img">
                <img
                  class="person-img"
                  src="@assets/img/main/baccarat_person.png"
                />
                <img class="bg-img" src="@assets/img/main/baccarat_bg.png" />
                <img
                  class="bg-front"
                  src="@assets/img/main/baccarat_title_bg.png"
                />
              </div>
            </div>
          </li>
          <li class="category-list">
            <ul class="category-box box2">
              <li class="box-list">
                <p class="category-title">LIVE GAMES</p>
                <div class="animation-img racing">
                  <img
                    class="person-img"
                    src="@assets/img/main/blackjack_person.png"
                  />
                  <img class="bg-img" src="@assets/img/main/blackjack_bg.png" />
                </div>
              </li>
              <li class="box-list">
                <p class="category-title">EGM</p>
                <div class="animation-img racing">
                  <img
                    class="person-img"
                    src="@assets/img/main/sicbo_dice.png"
                  />
                  <img class="bg-img" src="@assets/img/main/sicbo_bg.png" />
                </div>
              </li>
            </ul>
          </li>
          <li class="category-list">
            <div class="category-box">
              <p class="category-title">WINNBALL</p>
              <div class="animation-img racing">
                <img
                  class="person-img chip"
                  src="@assets/img/main/winnball_chip.png"
                />
                <img class="bg-img" src="@assets/img/main/winnball_bg.png" />
                <img
                  class="bg-front"
                  src="@assets/img/main/winnball_title_bg.png"
                />
              </div>
            </div>
          </li>
          <li class="category-list box3">
            <ul class="category-box category-jackpot">
              <li class="jackpot-list">
                <img src="@assets/img/category_jackpot00.png" />
              </li>
              <li class="jackpot-list">
                <img src="@assets/img/category_jackpot01.png" />
              </li>
              <li class="jackpot-list">
                <img src="@assets/img/category_jackpot02.png" />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section> -->
    <section class="slot-banner xs-section">
      <ul class="banner-lists">
        <li class="banner-list" v-for="item in bannerGameList" :key="item.src">
          <div class="banner-box">
            <img
              :src="require(`@assets/img/banner/${item.src}`)"
              :alt="item.alt"
            />
          </div>
          <div class="banner-hover">
            <div
              class="banner-title"
              v-text="item.title"
              style="white-space: pre-line"
            ></div>
            <div class="banner-button">
              <v-btn class="list-btn" @click="goToAllGames(item.gameId)">{{
                $t("mainBanner.gameList")
              }}</v-btn>
              <v-btn class="join-btn">
                <v-icon></v-icon>
                {{ $t("mainBanner.quickJoin") }}
              </v-btn>
            </div>
          </div>
        </li>
      </ul>
    </section>
    <!-- all game section -->
    <GameGroupForm />
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/css/swiper.css";

import GameGroupForm from "@views/components/common/GameGroupForm.vue";

export default {
  name: "MainPage",
  components: {
    GameGroupForm
  },
  data() {
    return {
      bannerGameList: [
        {
          gameId: 1,
          title: "Double \n Money Link",
          alt: "banner 01",
          src: "banner01.png"
        },
        {
          gameId: 2,
          title: "Duo Fu \n Duo Cai",
          alt: "banner 02",
          src: "banner02.png"
        },
        {
          gameId: 3,
          title: "5 Dragons \n Ultra",
          alt: "banner 03",
          src: "banner03.png"
        },
        {
          gameId: 4,
          title: "fudai \n lian lian",
          alt: "banner 04",
          src: "banner04.png"
        },
        {
          gameId: 5,
          title: "Huff N \n More Puff",
          alt: "banner 05",
          src: "banner05.png"
        },
        {
          gameId: 6,
          title: "Coin \n Combo",
          alt: "banner 06",
          src: "banner06.png"
        }
      ]
      // swiperOption: {
      //   slidesPerView: "auto",
      //   spaceBetween: 10,
      //   observer: true,
      //   observeParents: true,
      //   loop: true,
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true
      //   }
      // }
    };
  },
  methods: {
    goToAllGames(gameId) {
      this.$router.push({ name: "all-games", query: { gameType: gameId } });
    }
  }
};
</script>
