<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ $t("signIn.signInTitle") }}</h5>
      <button
        type="button"
        class="btn-close"
        @click="closeDialog(false)"
      ></button>
    </div>
    <!-- <div class="modal-body">
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="items.password">
          <v-form class="login-form" ref="form" lazy-validation>
            <div class="form-box">
              <div class="form-group m-0">
                <label>Id</label>
                <v-text-field
                  id="idField"
                  v-model="idField"
                  :rules="idRules"
                  required
                  placeholder="Enter your Id"
                  autocomplete="off"
                  @keydown.enter.prevent
                ></v-text-field>
              </div>
            </div>
            <div class="form-box password">
              <div
                class="form-group password"
                :class="pwdField ? 'active' : ''"
              >
                <label>Password</label>
                <v-text-field
                  id="pwdField"
                  type="password"
                  v-model="pwdField"
                  :rules="pwdRules"
                  required
                  placeholder="Enter your Password"
                  autocomplete="off"
                  @keydown.enter.prevent="handleLogin()"
                ></v-text-field>
              </div>
            </div>

            <div class="login-message d-flex">
              <button class="forget-pw">forget Password?</button>
            </div>
          </v-form>
          <div class="login-button">
            <button class="login-btn" @click="handleLogin()">Sign In</button>
          </div>
        </v-tab-item>
        <v-tab-item :key="items.code">
          <v-form class="verify-form" ref="verify_form" lazy-validation>
            <div class="form-box">
              <div class="form-group phone m-0">
                <label>Phone</label>
                <vue-tel-input-vuetify
                  label=""
                  :inputOptions="inputOptions"
                  v-model="phone"
                ></vue-tel-input-vuetify>
              </div>
            </div>
            <div class="form-box">
              <div class="form-group">
                <label>Verification Code</label>
                <v-text-field
                  v-model="mobileField"
                  required
                  placeholder="Enter your Verification Code"
                  autocomplete="off"
                ></v-text-field>
                <button class="send">Send</button>
              </div>
            </div>
            <div class="login-message d-flex">
              <button class="forget-pw">forget Password?</button>
            </div>
          </v-form>
          <div class="login-button">
            <button class="login-btn">Sign In</button>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="sns-button">
        <span class="line"></span>
        <ul class="sns-lists">
          <li class="sns-list line">
            <a class="sns-box" href="#"></a>
          </li>
          <li class="sns-list twitter">
            <a class="sns-box" href="#"></a>
          </li>
          <li class="sns-list google">
            <a class="sns-box" href="#"></a>
          </li>
        </ul>
        <span class="line"></span>
      </div>
      <div class="account">
        <p class="register-text">
          Don't have an account? <a>Register and Account</a>
        </p>
      </div>
    </div> -->
    <div class="modal-body">
      <v-form class="login-form" ref="form" lazy-validation>
        <div class="form-box">
          <div class="form-group m-0">
            <label>Id</label>
            <v-text-field
              id="idField"
              v-model="idField"
              :rules="idRules"
              required
              :placeholder="$t('signIn.idPlaceholder')"
              autocomplete="off"
              @keydown.enter.prevent
            ></v-text-field>
          </div>
        </div>
        <div class="form-box password">
          <div class="form-group password" :class="pwdField ? 'active' : ''">
            <label>Password</label>
            <v-text-field
              id="pwdField"
              type="password"
              v-model="pwdField"
              :rules="pwdRules"
              required
              :placeholder="$t('signIn.pwPlaceholder')"
              autocomplete="off"
              @keydown.enter.prevent="handleLogin()"
            ></v-text-field>
          </div>
        </div>
      </v-form>
      <div class="login-button">
        <button class="login-btn" :disabled="disableBtn" @click="handleLogin()">
          {{ $t("signIn.signInTitle") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { AuthNamespace, AuthActions } from "@/store/auth";
export default {
  data() {
    return {
      SignInDialog: false,
      inputOptions: {
        showDialCode: true,
        tabindex: 0
      },
      // id, password input value 및 rules
      valid: false,
      idField: "",
      idRules: [
        v => !!v || this.$t("signIn.idEmptyRules"),
        v => !(v && v.length >= 20) || this.$t("signIn.idLengthRules"),
        v =>
          !/[~!@#$%^&*()_+|<>?:{}]/.test(v) ||
          this.$t("signIn.idCharacterRules")
      ],
      pwdField: "",
      pwdRules: [
        v => !!v || this.$t("signIn.pwEmptyRules"),
        v =>
          /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/.test(
            v
          ) || this.$t("signIn.pwValidateRules")
      ],
      phone: null,
      mobileField: "",
      // login tab
      tab: null,
      items: ["password", "code"]
    };
  },
  computed: {
    ...mapGetters(AuthNamespace, [
      "getPlayerId",
      "getExternalToken",
      "getUserCredit",
      "getLoginCheck"
    ]),

    disableBtn() {
      if (this.idField == "" || this.pwdField == "") {
        return true;
      } else if (!this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },

    calcUserCredit() {
      return Number(this.getUserCredit).toLocaleString();
    }
  },
  watch: {
    SignInDialog(val) {
      if (val) {
        this.idField = "";
        this.pwdField = "";
      }
    }
  },
  methods: {
    ...mapActions(AuthNamespace, {
      ACTION_AUTH_LOGIN: AuthActions.ACTION_AUTH_LOGIN
    }),
    async handleLogin() {
      const validate = this.$refs.form.validate();

      if (validate) {
        await this.ACTION_AUTH_LOGIN({
          user_id: this.idField,
          password: this.pwdField
        }).then(async res => {
          if (res !== undefined) {
            if (window.location.pathname == "/") {
              if (process.env.NODE_ENV === "local") {
                window.location.replace(
                  `http://localhost:8080/?playerId=${this.getPlayerId}&externalToken=${this.getExternalToken}`
                );
              } else {
                window.location.replace(res);
              }
            } else {
              window.location.reload();
              //this.$router.go(this.$router.currentRoute);
            }
          }
        });
      }
    },
    // 모달 닫기 이벤트
    closeDialog(changed) {
      this.$emit("closeDialog", changed);
    }
  }
};
</script>
