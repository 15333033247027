<template>
  <v-dialog
    :value="dialog"
    @input="$emit('input', $event)"
    max-width="fit-content"
    persistent
    content-class="playview-dialog"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ machine.title }}</h5>
        <button
          type="button"
          class="btn-close"
          @click="$emit('close')"
        ></button>
      </div>
      <div class="modal-body">
        <div class="video-area screen2" v-if="getMachineGameType == '2screen'">
          <div class="multi-screen">
            <MachineDeviceVideoStream
              id="capture_up"
              :play="dialog"
              :device="devices.capture_up"
            />
          </div>
          <div class="multi-screen">
            <MachineDeviceVideoStream
              id="capture_down"
              :play="dialog"
              :device="devices.capture_down"
            />
          </div>
        </div>
        <div class="video-area wide" v-else>
          <div class="wide-screen">
            <MachineDeviceVideoStream
              id="capture_up"
              :play="dialog"
              :device="devices.capture_down"
            />
          </div>
        </div>

        <div class="play-button">
          <button @click="$emit('handleGame')">PLAY NOW</button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { AuthNamespace } from "@/store/auth";
import { MachineNamespace } from "@/store/machine";
import {
  MachineDeviceNamespace,
  MachineDeviceActions
} from "@/store/machineDevice";
import MachineDeviceVideoStream from "@views/components/common/MachineDeviceVideoStream.vue";

export default {
  components: {
    MachineDeviceVideoStream
  },
  props: {
    dialog: Boolean,
    machine: Object,
    external_token: String
  },
  computed: {
    ...mapGetters(AuthNamespace, ["getExternalToken"]),

    ...mapGetters(MachineDeviceNamespace, ["getMachineDeviceList"]),

    ...mapGetters(MachineNamespace, ["getMachineGameType"])
  },
  data() {
    return {
      devices: {
        capture_up: null,
        capture_down: null
      }
    };
  },
  methods: {
    ...mapActions(MachineDeviceNamespace, {
      ACTION_READ_MACHINE_DEVICE_LIST:
        MachineDeviceActions.ACTION_READ_MACHINE_DEVICE_LIST
    })
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.ACTION_READ_MACHINE_DEVICE_LIST({
          machine_id: this.machine.machine_id,
          external_token: this.getExternalToken
        }).then(() => {
          if (this.getMachineDeviceList.length) {
            this.devices.capture_up = this.getMachineDeviceList.find(
              e => e.type === "Capture_up"
            );
            this.devices.capture_down = this.getMachineDeviceList.find(
              e => e.type === "Capture_down"
            );
          }
        });
      }
    }
  }
};
</script>
