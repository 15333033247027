import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import authModule from "./auth"; // 인증 및 사용자 정보
import machineModule from "./machine"; // 머신 정보
import machineDeviceModule from "./machineDevice"; // 머신 장치 정보
import currencyModule from './currency'; // 통화 설정 정보

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authModule,
    machine: machineModule,
    machineDevice: machineDeviceModule,
		currency: currencyModule,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "machine", "machineDevice", 'currency']
    })
  ]
});
