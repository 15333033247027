<template>
  <v-footer class="xs-footer" :class="isOpen ? 'active' : ''">
    <div class="footer-inner">
      <!-- BMM 인증을 위해 임시 숨김 영역 처리 -->
      <!-- <div class="footer-link">
        <div class="footer-path">
          <ul class="path-lists">
            <li class="path-list">
              <a href="#">VIP Club</a>
            </li>
            <li class="path-list">
              <a href="#">Bonus</a>
            </li>
            <li class="path-list">
              <a href="#">About us</a>
            </li>
            <li class="path-list">
              <a href="#">Terms & Service</a>
            </li>
          </ul>
        </div>
        <div class="footer-sns">
          <ul class="sns-lists">
            <li class="sns-list">
              <div class="sns-box">
                <a href="#">
                  <img src="@assets/img/footer/facebook_icon.png" />
                </a>
              </div>
            </li>
            <li class="sns-list">
              <div class="sns-box">
                <a href="#">
                  <img src="@assets/img/footer/instar_icon.png" />
                </a>
              </div>
            </li>
            <li class="sns-list">
              <div class="sns-box">
                <a href="#">
                  <img src="@assets/img/footer/x_icon.png" />
                </a>
              </div>
            </li>
            <li class="sns-list">
              <div class="sns-box">
                <a href="#">
                  <img src="@assets/img/footer/youtube_icon.png" />
                </a>
              </div>
            </li>
            <li class="sns-list">
              <div class="sns-box">
                <a href="#">
                  <img src="@assets/img/footer/mail_icon.png" />
                </a>
              </div>
            </li>
            <li class="sns-list">
              <div class="sns-box">
                <a href="#">
                  <img src="@assets/img/footer/line_icon.png" />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-logos">
        <ul class="logo-lists">
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/visa_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/mastercard_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/krw_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/bitcion_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/ethereum_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/bnbcion_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/tron_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/tether_icon.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/usdcoin_icon.png" />
            </div>
          </li>
        </ul>
      </div>
      <div class="footer-text">
        <p class="exp-text">
          This website is operated by Westward Way Tech N.V. (registration No.
          158203), with address at Abraham de Veerstraat 9, Curacao. This
          website is operated under licence number: 365/JAZ issued by Gaming
          Service Provider, Authorised and Regulated by the Government of
          Curacao. Apollo MKT Limited, Reg No. HE 418946, having its registered
          office at Strovolou 47, Kyros Tower, 5th floor, Strovolos 2018,
          Nicosia, Cyprus, which provides management, payment and support
          services related to the operation of the website. Gambling can be
          addictive. Play responsibly.
        </p>
      </div> -->
      <!-- <div class="footer-logos bottom">
        <ul class="logo-lists">
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/winntech_01.png" />
            </div>
          </li>
          <li class="logo-list">
            <div class="logo-box">
              <img src="@assets/img/footer/18_icon.png" />
            </div>
          </li>
        </ul>
      </div> -->
      <p class="copyright">ⓒ XGAMES all rights reserved</p>
    </div>
  </v-footer>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>
